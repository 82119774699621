import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['item', 'input']

  connect() {
  }

  search(event) {
    const query = event.currentTarget.value;

    if (this.hasItemTarget) {
      this.itemTargets.forEach((item) => {
        if (this.searchForText(item, query)) {
          item.classList.remove('hidden');
        } else {
          item.classList.add('hidden');
        }
      });
    }


  }

  searchForText(item, searchText) {
    const searchTextLower = searchText.toLowerCase();

    return item.innerHTML.toLowerCase().includes(searchTextLower);
  }
}