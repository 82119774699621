import { Controller } from "stimulus"
import { slick } from 'slick-carousel'
import 'slick-carousel/slick/slick.css'

export default class extends Controller {
  static targets = ['wrapper']

  connect() {
    this.initSlides()
  }

  initSlides() {
    if (this.hasWrapperTarget) {
      $(this.wrapperTargets).each(function (_index, wrapper) {
        if ($(wrapper).hasClass('slick-initialized')) { return true }

        $(wrapper).slick({
          infinite: true,
          slidesToScroll: 1,
          variableWidth: true,
          lazyLoad: 'ondemand',
          prevArrow: '<button type="button" class="slick-custom-arrow slick-prev"><svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path></svg></button>',
          nextArrow: '<button type="button" class="slick-custom-arrow slick-next"><svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path></svg></button>'
        });
      });
    }
  }
}