import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['drawer']

  connect() {
    if (this.hasDrawerTarget) {
      this.frame = document.getElementById('drawer-frame');
      this.initDrawer();  
    }
  }

  initDrawer() {
    this.drawer = new Drawer(this.drawerTarget, this.options);
    this.drawer.hide();
    this.drawerTarget.classList.remove('hidden')
  }

  show(event) {
    event.preventDefault()
    this.frame.src = event.params.url
    this.drawer.show();
  }

  hide(event) {
    event.preventDefault()
    this.frame.src = ''
    this.drawer.hide();
  }

  get options() {
    const drawerFrame = document.getElementById("drawer-frame");
    const modalLoaderHTML = document.getElementById("drawer-loader").innerHTML;

    return {
      placement: 'right',
      backdrop: true,
      bodyScrolling: false,
      edge: false,
      edgeOffset: '',
      backdropClasses: 'backdrop',
      onShow: () => {
        drawerFrame.innerHTML = modalLoaderHTML;
      },
      onHide: () => {
        drawerFrame.innerHTML = modalLoaderHTML;
      },
    }
  }

}