import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["message", "waiting"];

  connect() {
    this.startWaitingAnimation();
  }

  startWaitingAnimation() {
    this.waitingWidth = 0
    this.waitingID = setInterval(this.frame.bind(this), 100)
  }

  frame() {
    if (this.waitingWidth >= 100) {
      clearInterval(this.waitingID);
      this.messageTarget.remove();
      this.waitingWidth = 0;
    } else {
      this.waitingWidth++;
      this.waitingTarget.style.width = `${this.waitingWidth}%`;
    }
  }
}
