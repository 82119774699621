import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { Autocomplete } from 'stimulus-autocomplete'
import Flatpickr from 'stimulus-flatpickr'
import Lightbox from 'stimulus-lightbox'

const application = Application.start()

const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
const componentContext = require.context(
  "../../components/",
  true,
  /(.*)\/.+\.js$/
);
application.load(definitionsFromContext(componentContext));

// Third-party controllers
application.register('autocomplete', Autocomplete)
application.register('flatpickr', Flatpickr)
application.register('lightbox', Lightbox)