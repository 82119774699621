import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.adjustTextarea();
    this.inputTarget.style.minHeight = `${this.inputTarget.scrollHeight}px`;
  }

  resize(event) {
    this.adjustTextarea(event.target);
  }

  adjustTextarea(textarea = this.inputTarget) {
    textarea.style.resize = 'none';
    textarea.style.overflow = 'hidden';

    const maxHeight = Math.floor(screen.height / 3);
    const height = Math.min(textarea.scrollHeight, maxHeight);

    textarea.style.height = `${height}px`;
  }
}
