import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import debounce from 'lodash.debounce';

export default class extends Controller {
  connect() {
    this.savingThreshold = 500;
    this.savingDelay = 1500;
    this.savingTries = 10;
    this.currentTry = 0;
    this.save = debounce(this.save.bind(this), this.savingThreshold);
    this.form = this.element;
    this.persisted = document.getElementById('referral_persisted').value;
    this.footer = document.getElementById('footer');

    this.form.addEventListener('change', () => {
      this.footer.classList.add('disabled')
      if (this.persisted === 'false') {
        this.form.classList.add('disabled');
      }
      this.save();
    });

    document.addEventListener('turbo:before-fetch-response', () => {
      setTimeout(() => {
        this.footer.classList.remove('disabled')
        this.form.classList.remove('disabled');
      }, 500);
    });
  }

  save() {
    if (!this.formIsBusy) {
      this.currentTry = 0; 
      if (window._rails_loaded) {
        Rails.fire(this.form, 'submit');
      } else {
        this.form.requestSubmit();
      }
    } else {
      if (this.currentTry < this.savingTries) {
        this.currentTry++;
        setTimeout(this.save, this.savingDelay);
      }
    }
  }

  get formIsBusy() {
    const formIsBusy = document.getElementById('referral_busy');
    if (formIsBusy) {
      return false;
    } else {
      return formIsBusy.value === "true";
    }
  }
}
