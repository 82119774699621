import { Controller } from "stimulus"
import { Popover } from 'flowbite';

export default class extends Controller {
  static targets = ['target', 'trigger'];

  connect() {
    this.popover = new Popover(this.targetTarget, this.triggerTarget, this.options);
  }

  get options() {
    return {
      placement: 'top',
      triggerType: 'click',
    }  
  } 
}