import { Controller } from "stimulus"
import dataTable from "datatables.net"

export default class extends Controller {
  static targets = ['table']

  connect() {    
    if (this.hasTableTarget) {
      $(this.tableTargets).each(function(_index, element) {

        $(element).dataTable({
          retrieve: true,
          info: false,
          paging: false,
          order: [],
          stateSave: true,
          stateSaveParams: function (_settings, data) {
            delete data.search;
          },
          "fnDrawCallback": function(oSettings) {
            $('.dataTables_filter input').addClass('form-input');

            if (oSettings.fnRecordsTotal() <= 5) {
              $('.dataTables_filter').hide();
            } else {
              $('.dataTables_filter').show();
            }
          }
        });

        this.rowClick(element)
      }.bind(this));
    }
  }

  rowClick(table) {
    $(table).find('tbody tr').each(function() {
      const link = $(this).find('a:first');

      if (link.length) {
        $(this).css('cursor', 'pointer').on('click', () => {
          window.location.href = link.attr('href');
        });
      }
    });
  }
}
