import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal']

  connect() {
    if (this.hasModalTarget) {
      this.initModal();  
    }
  }

  initModal() {
    this.modal = new Modal(this.modalTarget, this.options);
    this.frame = document.getElementById('modal-frame');
  }

  show(event) {
    event.preventDefault()
    this.frame.src = event.params.url
    this.reload = event.params.reload
    this.modal.show();
  }

  hide(event) {
    event.preventDefault()
    this.frame.src = ''
    this.modal.hide(); 
  }

  get options() {
    const modalFrame = document.getElementById("modal-frame");
    const modalLoaderHTML = document.getElementById("modal-loader").innerHTML;
    const that = this

    return {
      placement: 'center-center',
      backdrop: 'static',
      backdropClasses: 'backdrop',
      closable: false,
      onShow: () => {
        modalFrame.innerHTML = modalLoaderHTML;
      },
      onHide: () => {
        modalFrame.innerHTML = modalLoaderHTML;
        if (this.reload == true) {
          location.reload();
        }
      },
    }
  }

}