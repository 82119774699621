import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['role', 'specialty', 'referral_notification']

  connect() {
    if (this.hasSpecialtyTarget) {
      this.toggleSpecialty()
    }
  }

  toggleSpecialty() {
    $(this.specialtyTarget).toggleClass('hidden', !this.isSpecialist);
    $(this.specialtyTarget).find('input').prop('required', this.isSpecialist);
  }

  toggleReferralNotifications() {
    $(this.specialtyTarget).toggleClass('hidden', !this.isSpecialist);
    $(this.specialtyTarget).find('input').prop('required', this.isSpecialist);
  }

  get isSpecialist() {
    let checkedValue = $(this.roleTarget).closest('.collection-wrapper').find('input:checked').val()
    return checkedValue == 'specialist'
  }
}