import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['reason']

  connect() {
    if (this.hasReasonTarget) {
      this.handleReasonFields(this.reasonTarget.value)
    }

    $(document).on('change', '#referral_patient_id', function(e) {
      $('#new-patient-selected').remove()
    })
  }

  reasonChange(event) {
    this.handleReasonFields(event.currentTarget.value)
  }

  handleReasonFields(reason) {
    // Non-selected options
    $(`.services-grid:not([data-reason-fields="${reason}"])`).each(function(_index, element) {
      $(element).addClass('!hidden')
      $(element).find('input').each(function(_index, input) {
        $(input).prop('checked', false)
      })
    })

    // Selected option
    $(`.services-grid[data-reason-fields="${reason}"]`).removeClass('!hidden')
  }
}