import Rails from "@rails/ujs"
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
import "flowbite/dist/flowbite.turbo.min.js"
import "./dark-mode"
import "venobox/dist/venobox.css"

Rails.start()
ActiveStorage.start()
Turbo.setProgressBarDelay(1000)

const images = require.context('../images', true)